const query = `mutation SEND_SHOP_ORDER($input: SendShopOrderInput!) {
  sendShopOrder(input: $input) {
    status
    message
    variableSymbol
    orderId
    mincovna
    sugar
  }
}
`;

export default async formData => {
    const graphqlUrl =
        process.env.WP_GRAPHQL_URI ||
        process.env.GATSBY_WP_GRAPHQL_URI ||
        "/graphql";

    const result = await fetch(graphqlUrl, {
        // eslint-disable-line no-undef
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify({
            query,
            variables: {
                input: {
                    clientMutationId: "order",
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    email: formData.email,
                    mobile: formData.mobile,
                    street: formData.street + " " + formData.streetNumber,
                    city: formData.city,
                    postcode: formData.postcode,
                    countryId: formData.countryId,
                    shippingAddress: formData.shippingAddress,
                    shippingAddressFirstName: formData.shippingAddressFirstName,
                    shippingAddressLastName: formData.shippingAddressLastName,
                    shippingAddressContactEmail:
                        formData.shippingAddressContactEmail,
                    shippingAddressPhone: formData.shippingAddressPhone,
                    shippingAddressStreet:
                        formData.shippingAddressStreet +
                        " " +
                        formData.shippingAddressStreetNumber,
                    shippingAddressCity: formData.shippingAddressCity,
                    shippingAddressPostCode: formData.shippingAddressPostCode,
                    shippingAddressCountryId: formData.shippingAddressCountryId,
                    birthdate: formData.birthdate,
                    termsAndConditions: formData.termsAndConditions,
                    termsAndConditionsCm: formData.termsAndConditionsCm,
                    gdprCm: formData.gdprCm,
                    gdpr: formData.gdpr,
                    products: formData.products,
                    adviser: formData.adviser,
                    type: formData.type,
                    companyName: formData.companyName,
                    registrationNumber: formData.registrationNumber,
                    vatNumber: formData.vatNumber,
                },
            },
        }),
    });
    return await result.json();
};
