import React from "react";
import accounting from "accounting";

import QRCode from "qrcode.react";
import style from "./checkoutThankYou.module.sass";
import Title from "../../components/Title";
import logoMincovna from "../../images/cm-logo.svg";

const CheckoutThankYou = ({ orderResponse }) => {
    const total = accounting.formatNumber(
        orderResponse.total.totalPrice,
        2,
        "",
        "."
    );
    const variableSymbol = orderResponse.variableSymbol;
    const generateQrCode =
        "SPD*1.0*ACC:CZ9208000000000997747359*AM:" +
        total +
        "*CC:CZK*MSG:KAPITOL GOLD*X-VS:" +
        variableSymbol +
        "";

    return (
        <>
            <section className={style.header}>
                <Title type="h1" mt={52} mb={16}>
                    Děkujeme za váš nákup
                </Title>
                <p>
                    Na váš e-mail <strong>{orderResponse.email}</strong> jsme
                    zaslali proforma fakturu a kopii platebních údajů, které
                    najdete níže.
                </p>
            </section>
            <section className={style.wrapper}>
                <img
                    src={logoMincovna}
                    alt="logo česká mincovna"
                    className={style.logo}
                />
                <div className={style.row}>
                    <div className={style.table}>
                        <Title type="h2">
                            ÚDAJE K PLATBĚ OBJEDNÁVKY
                            <> Č.&nbsp;{orderResponse.variableSymbol}</>
                            <small>
                                Platbu zpracovává náš partner Česká mincovna
                            </small>
                        </Title>
                        <table>
                            <tr>
                                <th>Částka</th>
                                <td>
                                    {accounting.formatNumber(
                                        orderResponse.total.totalPrice,
                                        0,
                                        " ",
                                        ","
                                    )}
                                    <span className={style.currency}>
                                        &nbsp;Kč
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <th>Variabilní symbol</th>
                                <td data-test="var-symbol">
                                    {orderResponse.variableSymbol}
                                </td>
                            </tr>
                            <tr>
                                <th>Na účet</th>
                                <td>997747359/0800</td>
                            </tr>
                        </table>
                    </div>
                    <div className={style.qrCode}>
                        <QRCode
                            value={generateQrCode}
                            size={185}
                            renderAs="svg"
                        />
                    </div>
                </div>

                <div className={style.description}>
                    <p>
                        Po spárování platby vám zašleme potvrzovací e-mail a
                        následující pracovní den zásilku odešleme. Obdržíte také
                        kód pro sledování zásilky.
                    </p>
                    <p>
                        Česká pošta garantuje doručení do druhého pracovního
                        dne.
                    </p>
                    <Title type="h3" mt={48}>
                        Investice do zlata - s námi vždy bezpečně a výhodně
                    </Title>
                </div>
            </section>
        </>
    );
};

export default CheckoutThankYou;
