import React, { useState, useContext, useEffect } from "react";
import style from "./checkout.module.sass";
import Button from "../Button";
import { navigate } from "gatsby";
import produce from "immer";

import { checkoutContext } from "../../context/checkoutContextProvider";
import SendOrderMutation from "./SendOrderMutation";
import CheckoutCart from "../../components/CheckoutCart/CheckoutCart";
import CheckoutSummary from "../../components/CheckoutSummary/CheckoutSummary";
import CheckoutThankYou from "../../components/CheckoutThankYou/CheckoutThankYou";
import CheckoutSteps from "../../components/CheckoutSteps/CheckoutSteps";
import CheckoutButtons from "../../components/CheckoutButtons/CheckoutButtons";
import CheckoutModal from "../../components/CheckoutModal/CheckoutModal";
import { cartSum } from "../../components/Cart/cartSum";

import { measuringPurchases, measuringOrderError } from "../../utils/gtm";

const Checkout = () => {
    const {
        setCart,
        setCheckoutForm,
        cart,
        checkoutForm,
        adviser,
    } = useContext(checkoutContext);

    const [orderResponse, setOrderResponse] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState(false);

    useEffect(() => {
        if (checkoutForm === false) {
            navigate("/e-shop/");
        }
    }, []);

    const updatedCart = produce(cart, draft => {
        for (const property in draft) {
            delete draft[property].featuredImage;
            delete draft[property].productName;
            delete draft[property].vat;
            delete draft[property].category;
        }
    });

    const sendOrder = async () => {
        setIsSubmitting(true);
        setSubmitError(false);

        const order = {
            ...checkoutForm,
            products: updatedCart,
            adviser: adviser,
        };
        const response = await SendOrderMutation(order);

        if (response && response.data) {
            if (response.data.sendShopOrder.mincovna != "true") {
                measuringOrderError("Mincovna");
                setSubmitError(true);
                setIsSubmitting(false);
            }

            if (response.data.sendShopOrder.sugar != "true") {
                measuringOrderError("Sugar");
                setSubmitError(true);
                setIsSubmitting(false);
            }

            if (response.data.sendShopOrder.status === "true") {
                const cartTotal = cartSum(cart);
                const email = checkoutForm.email;
                setOrderResponse({
                    ...response.data.sendShopOrder,
                    total: cartTotal,
                    email: email,
                });
                measuringPurchases({
                    ...response.data.sendShopOrder,
                    cart: cart,
                    total: cartTotal,
                });
                setCart({});
                setCheckoutForm(false);
            }
        } else {
            setSubmitError(true);
            setIsSubmitting(false);
        }

        setSubmitError(true);
        setIsSubmitting(false);
    };

    return (
        <>
            <CheckoutSteps step={orderResponse ? 3 : 2} />
            {!orderResponse && (
                <div className={style.row}>
                    <div className={style.summary}>
                        <CheckoutSummary checkoutForm={checkoutForm} />
                        {submitError && (
                            <div className={style.error}>
                                Objednávku se nepodařilo odeslat prosím zkuste
                                odeslat znovu nebo kontaktujte obchodní
                                zastoupení.
                            </div>
                        )}
                        {isSubmitting && <CheckoutModal />}

                        <CheckoutButtons>
                            <Button
                                type="link"
                                mt={20}
                                uri="/e-shop/delivery"
                                disabled={isSubmitting}
                                color="outline-gold-dark"
                            >
                                Zpět
                            </Button>
                            <Button
                                className={style.button}
                                type="submit"
                                onClick={() => sendOrder()}
                                disabled={isSubmitting}
                                data-test="order-button"
                            >
                                {isSubmitting
                                    ? "Odesílám objednávku"
                                    : "Dokončit objednávku"}
                            </Button>
                        </CheckoutButtons>
                    </div>
                    <CheckoutCart />
                </div>
            )}
            {orderResponse && (
                <CheckoutThankYou orderResponse={orderResponse} />
            )}
        </>
    );
};

export default Checkout;
