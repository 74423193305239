import React from "react";
import { Link } from "gatsby";
import Title from "../../components/Title";

import style from "./checkoutSummary.module.sass";

const CheckoutAddress = ({ checkoutForm, title, shippingAddress }) => (
    <div className={style.address}>
        <Title type={"h3"} color={"gold"} mb={12} mt={12}>
            {title}
        </Title>
        <address>
            <p>
                {shippingAddress
                    ? checkoutForm.shippingAddressFirstName
                    : checkoutForm.firstName}
                &nbsp;
                {shippingAddress
                    ? checkoutForm.shippingAddressLastName
                    : checkoutForm.lastName}
            </p>
            <p>
                {shippingAddress
                    ? checkoutForm.shippingAddressStreet
                    : checkoutForm.street}
                &nbsp;
                {shippingAddress
                    ? checkoutForm.shippingAddressStreetNumber
                    : checkoutForm.streetNumber}
            </p>
            <p>
                {shippingAddress
                    ? checkoutForm.shippingAddressCity
                    : checkoutForm.city}
                &nbsp;
                {shippingAddress
                    ? checkoutForm.shippingAddressPostCode
                    : checkoutForm.postcode}
            </p>
        </address>
        <Link
            to={
                shippingAddress
                    ? "/e-shop/delivery#shipping"
                    : "/e-shop/delivery#invoice"
            }
        >
            <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M14.3677 3.99683L15.5462 2.81832C15.9367 2.42779 16.5699 2.42779 16.9604 2.81832L18.6889 4.5468C19.0794 4.93732 19.0794 5.57049 18.6889 5.96101L17.5104 7.13952L14.3677 3.99683Z"
                    fill="#AD9752"
                />
                <path
                    d="M3.36841 14.9964L2.11133 18.5073C2.11133 18.9982 2.5093 19.3962 3.00022 19.3962L6.5111 18.1391L16.2535 8.39673L13.1108 5.25403L3.36841 14.9964Z"
                    fill="#AD9752"
                />
            </svg>
            upravit
        </Link>
    </div>
);

export default CheckoutAddress;
