import React from "react";
import style from "./coinSpinner.module.sass";
import classNames from "classnames";

const CoinSpinner = ({ color }) => (
    <div
        className={classNames({
            [style.coinSpinner]: true,
            [style.light]: color === "light",
        })}
    />
);

export default CoinSpinner;
