// Measure a view of product details. This example assumes the detail view occurs on pageload,
// and also tracks a standard pageview of the details page.

export const measuringViewOfProductDetail = productObj => {
    if (typeof window !== "undefined" && window.dataLayer !== "undefined") {
        const products = productObj.map(item => {
            return {
                name: item.productName, // Name or ID is required.
                id: item.itemId,
                price: item.priceVat,
                brand: item.emitent,
                category: "",
                variant: "",
            };
        });

        window.dataLayer.push({
            ecommerce: {
                detail: {
                    //'actionField': {'list': 'Apparel Gallery'},    // 'detail' actions have an optional list property.
                    products: products,
                },
            },
        });
    }
};

/**
 * Call this function when a user clicks on a product link. This function uses the event
 * callback datalayer variable to handle navigation after the ecommerce data has been sent
 * to Google Analytics.
 * @param {Object} productObj An object representing a product.
 */

export const measuringProductClicks = productObj => {
    if (typeof window !== "undefined" && window.dataLayer !== "undefined") {
        window.dataLayer.push({
            event: "productClick",
            ecommerce: {
                click: {
                    actionField: { list: "Search Results" }, // Optional list property.
                    products: [
                        {
                            name: productObj.productName, // Name or ID is required.
                            id: productObj.itemId,
                            price: productObj.priceToShow,
                            brand: productObj.emitent,
                            category: "",
                            variant: "",
                            position: "",
                        },
                    ],
                },
            },
            eventCallback: () => {},
        });
    }
};

// Measure adding a product to a shopping cart by using an 'add' actionFieldObject
// and a list of productFieldObjects.
export const measuringAddingProductToShoppingCart = productObj => {
    if (typeof window !== "undefined" && window.dataLayer !== "undefined") {
        window.dataLayer.push({
            event: "addToCart",
            ecommerce: {
                currencyCode: "CZK",
                add: {
                    // 'add' actionFieldObject measures.
                    products: [
                        {
                            //  adding a product to a shopping cart.
                            name: productObj.productName,
                            id: productObj.productId,
                            price: productObj.itemPrice,
                            brand: "",
                            category: "",
                            variant: "",
                            quantity: productObj.quantity,
                        },
                    ],
                },
            },
        });
    }
};

// Measure the removal of a product from a shopping cart.
export const measuringRemovingProductFromShoppingCart = productObj => {
    if (typeof window !== "undefined" && window.dataLayer !== "undefined") {
        window.dataLayer.push({
            event: "removeFromCart",
            ecommerce: {
                remove: {
                    // 'remove' actionFieldObject measures.
                    products: [
                        {
                            //  removing a product to a shopping cart.
                            name: productObj.productName,
                            id: productObj.productId,
                            price: productObj.itemPrice,
                            brand: "",
                            category: "",
                            quantity: productObj.quantity,
                        },
                    ],
                },
            },
        });
    }
};

export const measuringCheckoutSteps = (cart, step) => {
    if (typeof window !== "undefined" && window.dataLayer !== "undefined") {
        const objectArray = Object.entries(cart);
        let products = [];
        objectArray.forEach(([key, value]) => {
            products.push({
                name: value.productName,
                id: value.productId,
                price: value.itemPrice,
                brand: "",
                category: "",
                quantity: value.quantity,
            });
        });
        /**
         * A function to handle a click on a checkout button. This function uses the eventCallback
         * data layer variable to handle navigation after the ecommerce data has been sent to Google Analytics.
         */
        window.dataLayer.push({
            event: "checkout",
            ecommerce: {
                checkout: {
                    actionField: { step: step, option: "cart" },
                    proucts: products,
                },
            },
        });
    }
};

export const measuringPurchases = order => {
    if (typeof window !== "undefined" && window.dataLayer !== "undefined") {
        const objectArray = Object.entries(order.cart);

        let products = [];

        objectArray.forEach(([key, value]) => {
            products.push({
                name: value.productName,
                id: value.productId,
                price: value.itemPrice,
                brand: "",
                category: "",
                quantity: value.quantity,
            });
        });

        window.dataLayer.push({
            event: "purchase",
            ecommerce: {
                purchase: {
                    actionField: {
                        id: order.orderId, // Transaction ID. Required for purchases and refunds.
                        affiliation: "Online Store",
                        revenue: order.total.totalPrice, // Total transaction value (incl. tax and shipping)
                        tax: "",
                        shipping: order.total.delivery,
                    },
                    products: products,
                },
            },
        });
    }
};

export const measuringOrderError = error => {
    if (typeof window !== "undefined" && window.dataLayer !== "undefined") {
        window.dataLayer.push({
            eventCategory: "Error",
            eventAction: "API",
            eventLabel: error,
        });
    }
};

export const measuringFormError = error => {
    if (typeof window !== "undefined" && window.dataLayer !== "undefined") {
        window.dataLayer.push({
            eventCategory: "Error",
            eventAction: "Form",
            eventLabel: error.name,
            eventValue: error.value,
        });
    }
};
