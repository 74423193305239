import React from "react";
import SiteLayout from "../../components/SiteLayout";
import Block from "../../components/Block/";

import CheckoutConfirmation from "../../components/Checkout/Checkout";

const Confirmation = props => (
    <SiteLayout location={props.location} checkout={true}>
        <Block background={true} radialGradient={true} cart={true}>
            <CheckoutConfirmation />
        </Block>
    </SiteLayout>
);

export default Confirmation;
