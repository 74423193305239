import React from "react";
import Title from "../../components/Title";
import style from "./checkoutSummary.module.sass";

import CheckoutAddress from "./CheckoutAddress";

const CheckoutSummary = ({ checkoutForm }) => (
    <>
        <section className={style.row}>
            <CheckoutAddress
                title="Fakturační adresa"
                checkoutForm={checkoutForm}
                shippingAddress={false}
            />
            <CheckoutAddress
                title="Doručení na adresu"
                checkoutForm={checkoutForm}
                shippingAddress={!checkoutForm.shippingAddress}
            />
        </section>

        <section className={style.delivery}>
            <Title type={"h3"} color={"gold"} mb={12} mt={30}>
                způsob doručení
            </Title>
            <p>
                Vaši zásilku doručíme prostřednictvím služby Cenné psaní České
                pošty. Bude přepravována ve speciálním obalu, za zvýšených
                bezpečnostních opatření a samozřejmě pojištěna až do výše
                jednoho milionu korun.
            </p>

            <svg
                width="144"
                height="80"
                viewBox="0 0 144 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0)">
                    <path
                        d="M137.015 20.1922C125.717 16.617 116.689 13.7594 105.391 10.1843C97.6736 7.7421 89.9557 5.29992 82.2379 2.85774C79.73 2.06466 78.8899 1.81919 76.3821 1.02611C71.9996 -0.339749 71.9996 -0.339749 67.4667 1.05758C55.9996 4.67049 49.078 6.87978 37.6172 10.5053C29.9432 12.9349 22.2692 15.3645 14.5952 17.7878C12.1375 18.562 9.68614 19.3424 7.22846 20.1166C5.78018 20.5761 4.67046 20.8153 3.50432 21.8413C0.657922 24.359 0.0246929 27.7138 0.0246929 31.289V64.9633C0.0246929 67.1223 -0.175934 69.4574 0.419677 71.5534C1.6924 75.9846 5.82407 78.9996 10.3946 78.9996H128.777C132.269 78.9996 136 79.4024 139.116 77.4763C142.57 75.3363 144 71.7108 144 67.7895V29.8916C144 26.2661 142.232 21.6021 137.015 20.1922Z"
                        fill="#657C88"
                    />
                    <path
                        d="M57.7681 40.0316C57.7681 36.0976 60.232 33.7939 63.9311 33.7939C65.6113 33.7939 66.6395 34.1401 67.5298 34.65V36.9348H66.2634C65.8182 36.5194 65.0972 36.1417 64.0188 36.1417C62.2007 36.1417 61.1223 37.3628 61.1223 39.9057C61.1223 42.6563 62.0627 43.827 63.8621 43.827C65.1474 43.827 65.8809 43.3486 66.464 42.7822H67.6301V44.9663C66.721 45.6335 65.442 46.137 63.6615 46.137C60.0627 46.137 57.7681 43.934 57.7681 40.0316Z"
                        fill="white"
                    />
                    <path
                        d="M71.6302 42.1277C71.787 43.6572 72.5017 44.0537 73.7556 44.0537C74.765 44.0537 75.5174 43.7642 76.0503 43.3865H77.0095V45.2244C76.2196 45.6713 75.16 46.1182 73.2791 46.1182C70.1443 46.1182 68.5205 44.3621 68.5205 41.5108C68.5205 38.6092 70.4014 36.7838 73.1224 36.7838C75.9312 36.7838 77.3669 38.2819 77.3669 41.3409C77.3669 41.6996 77.3356 42.0269 77.3356 42.134L71.6302 42.1277ZM71.649 40.51H74.4578C74.4077 39.119 73.9437 38.6721 73.1224 38.6721C72.4202 38.6721 71.8183 39.0309 71.649 40.51Z"
                        fill="white"
                    />
                    <path
                        d="M81.5421 38.0174C82.3446 37.3502 83.4794 36.7775 84.796 36.7775C86.1503 36.7775 87.2412 37.3251 87.2412 39.3392V45.9734H84.1628V40.1449C84.1628 39.3518 83.8368 39.1504 83.1032 39.1504C82.539 39.1504 82.0437 39.3078 81.6111 39.4462V45.9797H78.5327V36.9222H80.6706C81.1471 36.9222 81.423 37.1111 81.5421 38.0174Z"
                        fill="white"
                    />
                    <path
                        d="M91.8429 38.0174C92.6454 37.3502 93.7802 36.7775 95.0968 36.7775C96.4511 36.7775 97.542 37.3251 97.542 39.3392V45.9734H94.4636V40.1449C94.4636 39.3518 94.1376 39.1504 93.404 39.1504C92.8398 39.1504 92.3445 39.3078 91.9119 39.4462V45.9797H88.8335V36.9222H90.9714C91.4479 36.9222 91.7238 37.1111 91.8429 38.0174Z"
                        fill="white"
                    />
                    <path
                        d="M101.774 42.1276C101.925 43.6571 102.646 44.0536 103.9 44.0536C104.909 44.0536 105.661 43.7641 106.194 43.3864H107.154V45.2243C106.364 45.6712 105.304 46.1181 103.423 46.1181C100.288 46.1181 98.6646 44.362 98.6646 41.5107C98.6646 38.6028 100.545 36.7837 103.273 36.7837C106.081 36.7837 107.517 38.2818 107.517 41.3408C107.517 41.6996 107.48 42.0269 107.48 42.1339L101.774 42.1276ZM101.787 40.5099H104.596C104.545 39.1189 104.081 38.672 103.26 38.672C102.558 38.672 101.962 39.0308 101.787 40.5099ZM102.301 36.3368V35.7703L102.865 33.8947H105.824V34.6311L104.094 36.3305H102.301V36.3368Z"
                        fill="white"
                    />
                    <path
                        d="M58.7773 49.2212H61.8933C64.4638 49.2212 66.1378 50.115 66.1378 52.6579C66.1378 55.2574 64.5265 56.2141 61.8744 56.2141H59.6801V61.2495H58.771L58.7773 49.2212ZM59.6864 55.4777H61.8807C64.0375 55.4777 65.2161 54.7539 65.2161 52.6956C65.2161 50.6311 63.9309 49.9765 61.8807 49.9765H59.6864V55.4777Z"
                        fill="white"
                    />
                    <path
                        d="M67.354 60.4818V59.7768H67.6988C68.1252 60.1733 68.9151 60.6517 70.2004 60.6517C71.843 60.6517 72.633 59.9656 72.633 58.7257C72.633 57.6745 72.0499 57.2465 70.238 56.957C68.5264 56.68 67.448 56.0821 67.448 54.5714C67.448 53.023 68.6643 52.1985 70.4449 52.1985C71.7427 52.1985 72.5327 52.5447 73.028 52.872V53.5769H72.6894C72.2067 53.1804 71.6111 52.872 70.4637 52.872C69.0092 52.872 68.2568 53.4259 68.2568 54.5022C68.2568 55.5345 68.8587 55.931 70.5828 56.2205C72.4512 56.5101 73.4794 57.0828 73.4794 58.6627C73.4794 60.3118 72.47 61.3252 70.2443 61.3252C68.702 61.3252 67.8618 60.8594 67.354 60.4818Z"
                        fill="white"
                    />
                    <path
                        d="M80.4829 60.0285C79.7305 60.8216 78.9092 61.3377 77.4358 61.3377C75.7744 61.3377 74.5581 60.425 74.5581 58.7445C74.5581 56.938 76.0503 56.1135 78.138 56.1135C78.997 56.1135 79.7493 56.2016 80.4829 56.3904V54.7728C80.4829 53.3818 79.5926 52.8782 78.2885 52.8782C76.9719 52.8782 76.0816 53.2244 75.5174 53.6713H75.16V52.9663C75.881 52.5698 76.8716 52.1921 78.3261 52.1921C80.138 52.1921 81.3418 52.8656 81.3418 54.7224V61.2559H81.1036C80.6083 61.2559 80.4891 61.0985 80.4891 60.381L80.4829 60.0285ZM80.4829 59.2103V57.0073C79.7995 56.8499 79.0471 56.7303 78.1882 56.7303C76.6145 56.7303 75.4296 57.2968 75.4296 58.6752C75.4296 60.104 76.3512 60.6705 77.555 60.6705C78.9907 60.6642 79.7807 60.0474 80.4829 59.2103Z"
                        fill="white"
                    />
                    <path
                        d="M84.5894 53.7216C85.5988 52.916 86.9342 52.1921 88.5079 52.1921C89.7555 52.1921 90.8214 52.7083 90.8214 54.584V61.2559H89.9624V54.6847C89.9624 53.2559 89.1913 52.916 88.1819 52.916C86.8653 52.916 85.5988 53.6524 84.5894 54.4266V61.2496H83.7305V52.2991H84.1254C84.5016 52.2991 84.5894 52.3999 84.5894 53.1929"
                        fill="white"
                    />
                    <path
                        d="M93.2285 61.2495V52.2991H93.5044C94.0185 52.2991 94.0875 52.3998 94.0875 53.2243V61.2495H93.2285ZM93.3476 51.4053V51.1284L94.6329 49.2212H95.4856V49.5611L93.8743 51.399H93.3476V51.4053Z"
                        fill="white"
                    />
                    <path
                        d="M24.7396 39.2259C24.9276 39.2385 25.1283 39.484 25.1408 39.6665C25.1596 40.3526 24.7709 40.8687 24.6957 41.5233C24.0436 45.5013 25.3414 49.1897 28.5515 51.7389C31.0092 53.5076 34.7834 54.7413 37.6298 52.9411C39.191 51.89 39.9308 50.2598 39.8556 48.4155C39.7427 46.6909 38.7709 45.0922 37.1847 44.211C35.7239 43.2794 33.3603 43.3864 31.9684 44.3935C30.1753 45.5957 29.4229 47.5784 29.7427 49.6996C29.9308 50.5808 30.3132 51.3927 30.9151 52.104C28.4449 51.1787 26.3132 48.4785 25.6988 45.9608C26.4574 43.0088 29.354 40.34 32.2882 39.5595C35.3414 38.7224 38.5201 39.5847 40.8524 41.4918C43.9245 44.0977 44.89 48.3652 43.586 52.0662C42.4888 55.3896 38.9088 57.9388 35.4794 58.2409C28.6769 59.1221 22.6079 56.7177 18.1001 51.7011C15.3101 48.2456 13.6236 44.2928 13.5483 39.7672C13.6424 39.4903 13.8869 39.2763 14.1816 39.2196"
                        fill="white"
                    />
                    <path
                        d="M48.1882 39.226C48.5706 39.27 48.6521 39.7295 48.8214 40.0002C50.2446 46.0678 48.1255 52.1166 42.8966 55.6855L41.9688 56.2331C44.5267 53.5958 45.7493 49.8129 44.7462 46.0993C45.2854 44.104 45.0408 41.6744 44.3763 39.8176C44.3261 39.5722 44.5393 39.3015 44.7462 39.226C45.8684 39.163 47.0283 39.226 48.1882 39.226Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="144" height="79.0435" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </section>

        <section className={style.payment}>
            <Title type={"h3"} color={"gold"} mb={12} mt={30}>
                způsob platby
            </Title>
            <p>
                Vzhledem k povaze poskytovaného zboží akceptujeme pouze platbu
                převodem. Po dokončení objednávky vám vygenerujeme údaje k
                platbě a do e-mailové schránky odešleme proforma fakturu.
            </p>
            <p className={style.paymentInfo}>
                <span>
                    <svg
                        width="23"
                        height="23"
                        viewBox="0 0 23 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M22.4665 11.0301V7.07422C22.4665 6.90189 22.3725 6.75305 22.2315 6.66689L16.9832 3.66672L11.7349 0.666554C11.5939 0.580387 11.4137 0.580387 11.2649 0.666554L6.01654 3.66672L0.768203 6.66689C0.627203 6.74522 0.533203 6.90189 0.533203 7.07422V11.0301C0.533203 11.2886 0.744703 11.5001 1.0032 11.5001H2.09987V19.3334H1.0032C0.744703 19.3334 0.533203 19.5449 0.533203 19.8034V21.9967C0.533203 22.2552 0.744703 22.4667 1.0032 22.4667H21.9965C22.255 22.4667 22.4665 22.2552 22.4665 21.9967V19.8034C22.4665 19.5449 22.255 19.3334 21.9965 19.3334H20.8999V11.5001H21.9965C22.255 11.5001 22.4665 11.2886 22.4665 11.0301ZM7.19154 18.5501H4.84154V11.5001H7.19154V18.5501ZM12.6749 18.5501H10.3249V11.5001H12.6749V18.5501ZM11.4999 8.95422C10.3092 8.95422 9.3457 7.99072 9.3457 6.80005C9.3457 5.60939 10.3092 4.64589 11.4999 4.64589C12.6905 4.64589 13.654 5.60939 13.654 6.80005C13.654 7.99072 12.6905 8.95422 11.4999 8.95422ZM18.1582 18.5501H15.8082V11.5001H18.1582V18.5501Z"
                            fill="#657C88"
                        />
                    </svg>
                    Bankovní převod na účet
                </span>
                <span>Zdarma</span>
            </p>
        </section>
    </>
);

export default CheckoutSummary;
